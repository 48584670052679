import { Component, OnInit } from '@angular/core';
import { Insomnia } from "@ionic-native/insomnia/ngx";
import { GameService, Game } from 'src/app/services/game.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { switchMap, map } from 'rxjs/operators';

var stopTime:number;
var stopDate:Date;
var currentDate:Date;
var diff:number;
var lives:number;


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})


export class NavbarComponent implements OnInit {


  messages: Observable<Game[]>;

  private games: Observable<Game[]>;
  idea: Game = {
    name: '',
    notes:  '',
    stoppedTime: '',
    lives: 0,
    page: 0,
    started: '',
    hint: false
  };


  
  elapsed: any = {
    h: "00",
    m: "00",
    s: "00",
  };
  progress: any = 0;
  overallProgress: any = 0;
  percent: number = 0;
  radius: number = 100;
  minutes: number = 1;
  seconds: any = 10;
  timer: any = false;
  overallTimer: any = false;
  fullTime: any = "00:01:30";

  public admin: boolean = false;
  countDownTimer: any = false;
  timeLeft: any = {
    m: "00",
    s: "00",
  };

  lives:any;
  levens:any;
  remainingTime = `${this.timeLeft.m}:${this.timeLeft.s}`;
  private ideaCollection: AngularFirestoreCollection<Game>;
  items: Observable<Game[]>;
  hint: Observable<Game[]>;

  constructor(    private insomnia: Insomnia, private gameService: GameService,private router: Router, private afs: AngularFirestore) { 

    this.ideaCollection = this.afs.collection<Game>('Games');
    this.items = this.ideaCollection.valueChanges({ idField: 'zghKW8dSepawPV3yNuQQ' });

    

  }

  ngOnInit() {
    // this.hints();
    this.levens = this.gameService.getIdea("zghKW8dSepawPV3yNuQQ").subscribe(g =>{
      this.lives = g.lives;
    });

      let id = "zghKW8dSepawPV3yNuQQ";
      if (id) {
        this.gameService.getIdea(id).subscribe(idea => {
          this.idea = idea;
          stopTime = parseInt(this.idea.stoppedTime);
          stopDate = new Date(stopTime);
          currentDate = new Date();
          diff = Math.abs(stopDate.getTime() - currentDate.getTime());
          this.fullTime = "00:" + this.convertToString(diff);
          console.log(this.fullTime);
          let countMinute = Date;
          this.startTimer();
          lives = this.idea.lives;
          this.levens = lives;
          this.gameService.updateTime(this.fullTime);

          
        });
      }  

    }

    getLevens() {
      let levens = [];
  
      return this.getData().pipe(
        switchMap(res => {
          levens = res;    
          return this.afs.collection('Games', ref => ref.orderBy('name')).valueChanges({ idField: 'id' }) as Observable<Game[]>
        }),
        map(messages => {
          for (let m of messages) {
            m.lives = m.lives;
          }
          return messages;
        })
      )
    }
    getData() {
      return this.afs.collection('Games').valueChanges({ idField: 'id' }) as Observable<any>;
    }
    // async  hints(): Promise<void> {
    //   let hintBool:boolean;

    //   this.items.subscribe(data =>  {
    //   hintBool =  data[0].hint;
    //   console.log("admin:", this.admin); 
    //   if(hintBool === true && this.admin === false){
    //   this.router.navigateByUrl('/hint;');
    //   this.gameService.setHintBack(); 
    //   } 
    //   });

    // }
   async misClicked(){
      let id = "zghKW8dSepawPV3yNuQQ";
      if (id) {
        this.gameService.getIdea(id).subscribe(idea => {
          this.idea = idea;
        });
       this.gameService.removeLive(lives); 
     }
     this.idea.lives = this.idea.lives - 1;
this.levens = this.idea.lives;
   console.log(this.levens);
   return this.levens;
    }
    
  touchMe() {
    console.log("touched");
  }
   convertToString(ms, delim = ":") {
    const showWith0 = value => (value < 10 ? `0${value}` : value);
    const hours = showWith0(Math.floor((ms / (1000 * 60 * 60)) % 60));
    const minutes = showWith0(Math.floor((ms / (1000 * 60)) % 60));
    const seconds = showWith0(Math.floor((ms / 1000) % 60));
    return `${parseInt(hours) ? `${hours}${delim}` : ""}${minutes}${delim}${seconds}`;
  }
  startTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      clearInterval(this.countDownTimer);
    }
    if (!this.overallTimer) {
      this.progressTimer();
      this.insomnia.keepAwake();
    }

    this.timer = false;
    this.percent = 0;
    this.progress = 0;

    let timeSplit = this.fullTime.split(":");
    this.minutes = timeSplit[1];
    this.seconds = timeSplit[2];

    let totalSeconds = Math.floor(this.minutes * 60) + parseInt(this.seconds);
    let secondsLeft = totalSeconds;

    let forwardsTimer = () => {
      if (this.percent == this.radius) clearInterval(this.timer);
      this.percent = Math.floor((this.progress / totalSeconds) * 100);
      ++this.progress;
    };

    let backwardsTimer = () => {
      if (secondsLeft >= 0) {
        this.timeLeft.m = Math.floor(secondsLeft / 60);
        this.timeLeft.s = secondsLeft - 60 * this.timeLeft.m;
        this.remainingTime = `${this.pad(this.timeLeft.m, 2)}:${this.pad(
          this.timeLeft.s,
          2
        )}`;
        secondsLeft--;
      }
 
    };

    // run once when clicked
    forwardsTimer();
    backwardsTimer();

    // timers start 1 second later
    this.countDownTimer = setInterval(backwardsTimer, 1000);
    this.timer = setInterval(forwardsTimer, 1000);
  }

  stopTimer() {
    clearInterval(this.countDownTimer);
    clearInterval(this.timer);
    clearInterval(this.overallTimer);
    this.countDownTimer = false;
    this.overallTimer = false;
    this.timer = false;
    this.percent = 0;
    this.progress = 0;
    this.elapsed = {
      h: "00",
      m: "00",
      s: "00",
    };
    this.timeLeft = {
      m: "00",
      s: "00",
    };
    this.remainingTime = `${this.pad(this.timeLeft.m, 2)}:${this.pad(
      this.timeLeft.s,
      2
    )}`;
    this.insomnia.allowSleepAgain();
    console.log("stop");
  }

  progressTimer() {
    let countDownDate = new Date();

    this.overallTimer = setInterval(() => {
      let now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = now - countDownDate.getTime();

      // Time calculations for hours, minutes and seconds

      this.elapsed.h = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.elapsed.m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.elapsed.s = Math.floor((distance % (1000 * 60)) / 1000);

      this.elapsed.h = this.pad(this.elapsed.h, 2);
      this.elapsed.m = this.pad(this.elapsed.m, 2);
      this.elapsed.s = this.pad(this.elapsed.s, 2);
    }, 1000);
  }

  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  updateMyDate($event) {
    console.log($event.split(":"));
  }
	public parseDates( fromValue: string, toValue: string ) : void {

		var fromMs = Date.parse( fromValue );
		var toMs = Date.parse( toValue );

		// Ensure that we have a valid date-range to work with.
		if ( isNaN( fromMs ) || isNaN( toMs ) || ( fromMs > toMs ) ) {

			console.group( "Invalid date range - no calculations to perform." );
			console.log( "From:", fromMs );
			console.log( "To:", toMs );
			console.groupEnd();
			return;

		}

	

		// Strip out any deltas that start with "0". These won't add any additional
		// insight above and beyond the previous delta calculations.
		// --
		// NOTE: Always using the first value, even if "0 Seconds".
	

	}

	// ---
	// PRIVATE METHODS.
	// ---

	// I calculate the delta breakdown using Day as the largest unit.
	private calculateDaysHoursMinutesSeconds( delta: number ) : number[] {

		var days = Math.floor( delta / 60 / 60 / 24 );
		var remainder = ( delta - ( days * 60 * 60 * 24 ) );

		return( [ days, ...this.calculateHoursMinutesSeconds( remainder ) ] );

	}


	// I calculate the delta breakdown using Hour as the largest unit.
	private calculateHoursMinutesSeconds( delta: number ) : number[] {

		var hours = Math.floor( delta / 60 / 60 );
		var remainder = ( delta - ( hours * 60 * 60 ) );

		return( [ hours, ...this.calculateMinutesSeconds( remainder ) ] );

	}


	// I calculate the delta breakdown using Minute as the largest unit.
	private calculateMinutesSeconds( delta: number ) : number[] {

		var minutes = Math.floor( delta / 60 );
		var remainder = ( delta - ( minutes * 60 ) );

		return( [ minutes, ...this.calculateSeconds( remainder ) ] );

	}


	// I calculate the delta breakdown using Second as the largest unit.
	private calculateSeconds( delta: number ) : number[] {

		return( [ delta ] );

	}


	// I calculate the delta breakdown using Week as the largest unit.
	private calculateWeeksDaysHoursMinutesSeconds( delta: number ) : number[] {

		var weeks = Math.floor( delta / 60 / 60 / 24 / 7 );
		var remainder = ( delta - ( weeks * 60 * 60 * 24 * 7 ) );

		return( [ weeks, ...this.calculateDaysHoursMinutesSeconds( remainder ) ] );

	}


	// I format the set of calculated delta-values as a human readable string.
	private format( values: number[] ) : string {

		var units: string[] = [ "Weeks", "Days", "Hours", "Minutes", "Seconds" ];
		var parts: string[] = [];

		// Since the values are calculated largest to smallest, let's iterate over them
		// backwards so that we know which values line up with which units.
		for ( var value of values.slice().reverse() ) {

			parts.unshift( value.toLocaleString() + " " + units.pop() );

		}

		return( parts.join( ", " ) );

	}
   
  numSequence(n: number): Array<number> {
    return Array(n);
  }

}

