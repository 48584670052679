import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

// Send unauthorized users to login
const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['/']);
 
// Automatically log in users
const redirectLoggedInToChat = () => redirectLoggedInTo(['/home']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    ...canActivate(redirectLoggedInToChat)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'stap1',
    loadChildren: () => import('./stap1/stap1.module').then( m => m.Stap1PageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap2',
    loadChildren: () => import('./stap2/stap2.module').then( m => m.Stap2PageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap3',
    loadChildren: () => import('./stap3/stap3.module').then( m => m.Stap3PageModule), data: {
      preload: false
    },
  },
  {
    path: 'hint',
    loadChildren: () => import('./hint/hint.module').then( m => m.HintPageModule), data: {
      preload: false
    },
  },
  {
    path: 'game',
    loadChildren: () => import('./game/game.module').then( m => m.GamePageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap4',
    loadChildren: () => import('./stap4/stap4.module').then( m => m.Stap4PageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap5',
    loadChildren: () => import('./stap5/stap5.module').then( m => m.Stap5PageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap5-a',
    loadChildren: () => import('./stap5-a/stap5-a.module').then( m => m.Stap5APageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap5-b',
    loadChildren: () => import('./stap5-b/stap5-b.module').then( m => m.Stap5BPageModule), data: {
      preload: false
    },
  },
  {
    path: 'stap5-c',
    loadChildren: () => import('./stap5-c/stap5-c.module').then( m => m.Stap5CPageModule), data: {
      preload: false
    },
  },
  {
    path: 'behaald',
    loadChildren: () => import('./behaald/behaald.module').then( m => m.BehaaldPageModule), data: {
      preload: false
    },
  },
  {
    path: 'beheer',
    loadChildren: () => import('./beheer/beheer.module').then( m => m.BeheerPageModule), data: {
      preload: false
    },
  },
  { path: 'ideas', loadChildren: './pages/idea-list/idea-list.module#IdeaListPageModule', data: {
    preload: false
  },
},
  { path: 'idea', loadChildren: './pages/idea-details/idea-details.module#IdeaDetailsPageModule' },
  { path: 'idea/:id', loadChildren: './pages/idea-details/idea-details.module#IdeaDetailsPageModule' },
  {
    path: 'hint-manier',
    loadChildren: () => import('./hint-manier/hint-manier.module').then( m => m.HintManierPageModule)
  },
  {
    path: 'stap6',
    loadChildren: () => import('./stap6/stap6.module').then( m => m.Stap6PageModule)
  },
  {
    path: 'stap6-b',
    loadChildren: () => import('./stap6-b/stap6-b.module').then( m => m.Stap6BPageModule)
  },
  {
    path: 'stap6-c',
    loadChildren: () => import('./stap6-c/stap6-c.module').then( m => m.Stap6CPageModule)
  },
  {
    path: 'stap-fout',
    loadChildren: () => import('./stap-fout/stap-fout.module').then( m => m.StapFoutPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
