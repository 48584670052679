import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { Interface } from 'readline';
import { Router } from '@angular/router';
import * as firebase from 'firebase';

export interface Game {
  id?: string,
  name: string,
  notes: string,
  stoppedTime: string,
  lives: number,
  page: number,
  started: string,
  hint: boolean
}
export interface Message {
  createdAt: firebase.firestore.FieldValue;
  id: string;
  from: string;
  msg: string;
  fromName: string;
  myMsg: boolean;
}
 
@Injectable({
  providedIn: 'root'
})
export class GameService {
  private ideas: Observable<Game[]>;
  private ideaCollection: AngularFirestoreCollection<Game>;
  private messageCollection: AngularFirestoreCollection<Message>;
  private deleteMessages: Observable<Message[]>;

 
  
  constructor(private afs: AngularFirestore,private router: Router) {
    this.ideaCollection = this.afs.collection<Game>('Games');
    this.messageCollection = this.afs.collection<Message>('messages');

    this.ideas = this.ideaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );




    this.deleteMessages = this.messageCollection.snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }))
  }
 
  getIdeas(): Observable<Game[]> {
    return this.ideas;
  }

  getLeven(){
    return this.ideas;
  }
 
  getIdea(id: string): Observable<Game> {
    return this.ideaCollection.doc<Game>(id).valueChanges().pipe(
      take(1),
      map(idea => {
        idea.id = id;
        return idea
      })
    );
  }
  getLives(): Observable<Game> {
    var id = "zghKW8dSepawPV3yNuQQ";
    return this.ideaCollection.doc<Game>(id).valueChanges().pipe(
      take(1),
      map(idea => {
        idea.id = id;
        return idea
      })
      
    );
    
  }

  addIdea(idea: Game): Promise<DocumentReference> {
    return this.ideaCollection.add(idea);
  }
 
  updateIdea(idea: Game): Promise<void> {
    return this.ideaCollection.doc(idea.id).update({hint:idea.hint, name: idea.name, notes: idea.notes, stoppedTime: idea.stoppedTime, lives: idea.lives, page: idea.page, started: idea.started });
  }
 
  removeLive(lives: number): Promise<void> {
      
    
      var oldLives = lives;
      console.log(oldLives);
      var newLives = oldLives - 1;
      console.log(newLives);

      if (newLives === 0) {
        console.log("af");
        this.router.navigateByUrl('/behaald', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/behaald']);
      }); 
        
      }
    return this.ideaCollection.doc("zghKW8dSepawPV3yNuQQ").update({lives: newLives });
  }
  setHint(): Promise<void> {
  return this.ideaCollection.doc("zghKW8dSepawPV3yNuQQ").update({hint: true });
}
setHintBack(): Promise<void> {
  return this.ideaCollection.doc("zghKW8dSepawPV3yNuQQ").update({hint: false });
}
  updateTime(fullTime: string): Promise<void> {
 
  return this.ideaCollection.doc("zghKW8dSepawPV3yNuQQ").update({notes: fullTime });
}
levelUp(): Promise<void> {
  
  return this.ideaCollection.doc("zghKW8dSepawPV3yNuQQ").update({page: firebase.firestore.FieldValue.increment(1) });
}



  deleteIdea(id: string): Promise<void> {
    return this.ideaCollection.doc(id).delete();
  }
  deleteChats(): Promise<void> {
    return this.deleteMessages.forEach(function(mes) {
      this.messageCollection.doc(mes).delete(); 
    });

  }
}
